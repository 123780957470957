html {
  @apply text-km-black;
}

.font-arno {
  @apply tracking-wider;
}

h1 {
  @apply font-arno text-3xl uppercase;
}

h2 {
  @apply font-arno text-2xl uppercase;
}

h3 {
  @apply font-arno text-xl uppercase;
}

h4,
h5,
h6 {
  @apply font-arno uppercase;
}

p {
  @apply font-light leading-relaxed;
}

.richtext {
  & h2 a,
  & h3 a,
  & h4 a,
  & p a,
  & li a {
    @apply break-words underline transition hover:text-bc-bronze;
  }
}

.button {
  @apply border-bc-gold bg-bc-gold hover:bg-transparent hover:text-bc-black;
}
