html {
  --tw-text-opacity: 1;
  color: rgb(71 78 77 / var(--tw-text-opacity));
}

.font-arno {
  letter-spacing: .05em;
}

h1 {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: arno-pro-caption, serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

h2 {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: arno-pro-caption, serif;
  font-size: 1.5rem;
  line-height: 2rem;
}

h3 {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: arno-pro-caption, serif;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h4, h5, h6 {
  text-transform: uppercase;
  letter-spacing: .05em;
  font-family: arno-pro-caption, serif;
}

p {
  font-weight: 300;
  line-height: 1.625;
}

.richtext h2 a, .richtext h3 a, .richtext h4 a, .richtext p a, .richtext li a {
  overflow-wrap: break-word;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.richtext h2 a:hover, .richtext h3 a:hover, .richtext h4 a:hover, .richtext p a:hover, .richtext li a:hover {
  --tw-text-opacity: 1;
  color: rgb(166 84 55 / var(--tw-text-opacity));
}

.button {
  --tw-border-opacity: 1;
  border-color: rgb(226 190 145 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(226 190 145 / var(--tw-bg-opacity));
}

.button:hover {
  --tw-text-opacity: 1;
  color: rgb(71 78 77 / var(--tw-text-opacity));
  background-color: #0000;
}

/*# sourceMappingURL=benjaminCrosland.css.map */
